import React from 'react';
import Eyebrow from '@components/core/Eyebrow';
import Text from '@components/core/Text';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Markdown from 'react-remarkable';
import styles from './section-header.module.scss';

const SectionHeader = ({
    eyebrow,
    sizeTitle,
    title,
    summary,
    variant,
    className,
    classTitleWrapper,
    classSummaryWrapper,
}) => {
    const classes = cx(styles.container, styles[variant], className);
    const titleClasses = cx(styles.title, classTitleWrapper);
    const summaryClasses = cx(styles.summary, classSummaryWrapper);
    return (
        <div className={classes}>
            {eyebrow && (
                <Eyebrow
                    className={styles.eyebrow}
                    casing="uppercase"
                    size="paragraphSm"
                    data-testid="section-header-eyebrow"
                >
                    {eyebrow}
                </Eyebrow>
            )}
            {title && (
                <Text
                    className={titleClasses}
                    size={sizeTitle}
                    as="h2"
                    color={variant}
                    data-testid="section-header-title"
                >
                    {title}
                </Text>
            )}
            {summary && (
                <Text
                    className={summaryClasses}
                    as="div"
                    size="paragraphMd"
                    color={variant}
                    data-testid="section-header-summary"
                >
                    <Markdown>{summary}</Markdown>
                </Text>
            )}
        </div>
    );
};

SectionHeader.propTypes = {
    eyebrow: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    className: PropTypes.string,
    classTitleWrapper: PropTypes.string,
    classSummaryWrapper: PropTypes.string,
    sizeTitle: PropTypes.string,
    variant: PropTypes.oneOf(['light', 'dark']),
};

SectionHeader.defaultProps = {
    eyebrow: null,
    title: null,
    summary: null,
    variant: 'light',
    className: null,
    sizeTitle: 'h2',
    classTitleWrapper: '',
    classSummaryWrapper: '',
};

export default SectionHeader;
